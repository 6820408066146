import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";

const Features = ({location}) => (
  <DefaultLayout
    title="Slack Integration"
    description="Slack is well known for it's powerful real-time communication features. Codecks' two-way integration allows you to keep track of the work that matters."
    location={location}
  >
    <Hero size="sm" title="Our Two-Way Slack Integration" />
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature
        childAsDescription
        title="Create cards from within Slack"
        img="slack-1.png"
      >
        The Codecks Slack Integrations allows you to create tasks for you or others from wherever
        you use slack. Just type <b>/addcard</b> and you'll find a new card on your hand. You may
        also click on the <b>More actions</b> button next to an existing message to create a card
        from it.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        childAsDescription
        title="Receive notifications about changes"
        img="slack-2.png"
        imgRight
      >
        You'll also be able to get a feel for your team's activity by enabling notifications for a
        Slack channel of your choice.
      </ThemedSingleFeature>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title="How to set it up?">
      <ThemedSingleFeature childAsDescription img="slack-3.png">
        First, go to your Organization Settings and find the Slack tab
      </ThemedSingleFeature>

      <ThemedSingleFeature childAsDescription img="slack-4.png" imgRight>
        Hit the <b>Add to Slack</b> button and you're asked to connect Codecks to one of your Slack
        channels. After completing this step, you are returned to your Codecks Account Settings and
        can customise the integration.
      </ThemedSingleFeature>
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
